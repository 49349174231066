import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import {
  MdOutlineSecurity,
  MdOutlineLockPerson,
  MdOutlineRuleFolder,
} from 'react-icons/md';
import { LuHeartHandshake } from 'react-icons/lu';
import { BsBarChartSteps } from 'react-icons/bs';
import { FaRegLightbulb } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';

const actions = [
  {
    title: 'Know Yourself',
    href: '/know-yourself',
    description: 'Share the fundamental information on your business to inform the other wizards and keep your stakeholders updated about your setup.',
    icon: FaRegLightbulb,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    enabled: true,
  },
  {
    title: 'Information Security',
    href: '#',
    description: 'Implement robust security measures to protect information from unauthorized access, modification, or destruction.',
    icon: MdOutlineSecurity,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    enabled: false,
  },
  {
    title: 'Data Privacy',
    href: '#',
    description: 'Ensure that personal data is handled in accordance with relevant laws and regulations to protect individual privacy.',
    icon: MdOutlineLockPerson,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    enabled: false,
  },
  {
    title: 'Legal, Tax & Financial Due Diligence',
    href: '#',
    description: 'Verify the financial, legal, and tax compliance of your business to make yourself ready for potential new shareholders.',
    icon: MdOutlineRuleFolder,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    enabled: false,
  },
  {
    title: 'Vision & Culture',
    href: '#',
    description: 'Define and nurture the core values and culture that drive your organization towards its mission.',
    icon: LuHeartHandshake,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    enabled: false,
  },
  {
    title: 'Quality Management System',
    href: '#',
    description: 'Establish a framework for continuous development of your processes and consistent quality assurance in your products and services.',
    icon: BsBarChartSteps,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    enabled: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const StartupWizardBlock = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.lordicon.com/lusqsztk.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-white overflow-hidden rounded-lg shadow mb-8">
      <div className="p-6 relative">
        <div>
          <span className="inline-flex rounded-lg p-3 bg-teal-50 text-teal-700 ring-4 ring-white">
            <lord-icon
              src="https://cdn.lordicon.com/fttvwdlw.json"
              stroke="bold"
              trigger="loop"
              colors="primary:#2a9d8f,secondary:#2a9d8f"
              style={{ width: '40px', height: '40px' }}
            ></lord-icon>
          </span>
        </div>
        <div className="mt-8">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            <Link to="/startup-wizard" className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              Startup Support
            </Link>
          </h3>
          <p className="mt-2 text-sm text-gray-500">
          Launch your startup with confidence. Get step-by-step guidance on legal forms, company setup, and more.
          This wizard guides you through the whole process of creating your company and raising the first external capital from investors.
          <br />
          <br />
          ⚠️ {' '}
          {' '}Only available for companies to be founded in{' '}
          <span role="img" aria-label="German Flag">🇩🇪</span> Germany.
          </p>
        </div>
        <span
          className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
          aria-hidden="true"
        >
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
            <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
          </svg>
        </span>
      </div>
    </div>
  );
};

export default function Wizards() {
  return (
    <div>
      <StartupWizardBlock />
      <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
              !action.enabled ? 'opacity-50 cursor-not-allowed' : ''
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'inline-flex rounded-lg p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                <Link to={action.enabled ? action.href : '#'} className={action.enabled ? 'focus:outline-none' : 'pointer-events-none'}>
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </Link>
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.description}
              </p>
            </div>
            <span
              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}