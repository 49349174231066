import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const KnowYourselfWizardMarketDrawer = ({ isOpen, subregion, countries, onClose }) => {
  const { t } = useTranslation();

  return (
    <div className={`fixed bottom-0 right-0 left-[256px] transition-transform transform ${isOpen ? 'translate-y-0' : 'translate-y-full'} bg-white shadow-lg z-30`}>
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-lg font-semibold">{t(`Subregions.${subregion}`)}</h2>
        <button 
          onClick={onClose} 
          className="text-gray-600 hover:text-gray-900 text-3xl"
        >
          &times;
        </button>
      </div>
      <div className="p-4">
        <p>{t('KnowYourselfWizard.marketDrawerDescription')}</p>
        <p className="mt-2 text-gray-700">
          {countries.map(country => (
            <span key={country.id} className="mr-2">
              {t(`Countries.${country.name}`)}
            </span>
          ))}
        </p>
      </div>
    </div>
  );
};

KnowYourselfWizardMarketDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  subregion: PropTypes.string,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      flag: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default KnowYourselfWizardMarketDrawer;