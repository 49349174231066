import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Flag from 'react-world-flags';

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsModalOpen(false);
  };

  if (!i18n.isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <footer className="bg-primary-color text-white py-4 fixed bottom-0 w-full">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="text-center md:text-left mb-2 md:mb-0">
          &copy; Cenedril, 2024
        </div>
        <div className="flex space-x-4 text-center md:text-left">
          <a href="#" className="hover:underline text-white">{t('imprint')}</a>
          <a href="#" className="hover:underline text-white">{t('privacy')}</a>
          <a href="#" className="hover:underline text-white">{t('terms')}</a>
        </div>
        <div className="mt-2 md:mt-0">
          <span className="mr-2 font-bold text-white cursor-pointer" onClick={() => setIsModalOpen(true)}>
            {t('chooseLanguage')}
          </span>
          <Transition.Root show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setIsModalOpen}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                      <div className="absolute top-0 right-0 pt-4 pr-4">
                        <button
                          type="button"
                          className="text-gray-400 hover:text-gray-500"
                          onClick={() => setIsModalOpen(false)}
                        >
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div>
                        <div className="mt-3 text-center sm:mt-5">
                          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                            {t('chooseLanguage')}
                          </Dialog.Title>
                          <div className="mt-2 flex flex-col items-center space-y-4">
                            <button
                              onClick={() => changeLanguage('en')}
                              className="flex items-center space-x-2 hover:bg-gray-100 px-4 py-2 rounded-md"
                            >
                              <Flag code="GB" style={{ width: '32px', height: '20px' }} />
                              <span>English</span>
                            </button>
                            <button
                              onClick={() => changeLanguage('de')}
                              className="flex items-center space-x-2 hover:bg-gray-100 px-4 py-2 rounded-md"
                            >
                              <Flag code="DE" style={{ width: '32px', height: '20px' }} />
                              <span>Deutsch</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      </div>
    </footer>
  );
}
