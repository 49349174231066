import React, { useState } from 'react';
import axios from '../../axios'; // Import the configured Axios instance
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { countryData } from '../../constants/countryData';

const sortedCountryData = [...countryData].sort((a, b) => a.name.localeCompare(b.name));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SignUp() {
  const [entityType, setEntityType] = useState('Company');
  const [companyName, setCompanyName] = useState('');
  const [website, setWebsite] = useState('');
  const [yearFounded, setYearFounded] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState(sortedCountryData[0]);
  const [language, setLanguage] = useState({ id: 'en', name: 'English' });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleEntityTypeChange = (type) => {
    setEntityType(type);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userPayload = {
        email,
        password,
        password_confirmation: confirmPassword,
        first_name: firstName,
        last_name: lastName,
        entity_type: entityType,
        entity_name: companyName,
        website,
        year_founded: parseInt(yearFounded, 10),
        country: country.code,
        language: language.id,
      };
  
      const response = await axios.post('/api/v1/users', { user: userPayload });
  
      if (response.data.message === 'User registered successfully') {
        const { id, entity_type, entity_id, authentication_token } = response.data;
  
        if (entity_type === 'Company') {
          await axios.post('/api/v1/know_yourself_responses', {
            know_yourself_response: {
              company_id: entity_id,
              responses: {
                offices: [{ location: '', employees: 1 }],
                remoteWorkers: 0,
                industry: '',
                section: ''
              },
              updated_by: id
            }
          }, {
            headers: {
              Authorization: `Bearer ${authentication_token}`
            }
          });
        }
  
        sessionStorage.setItem('registrationMessage', 'Registration successful. Please log in.');
        navigate('/registration-success');
      } else {
        setMessage(t('error_registering_user'));
      }
    } catch (error) {
      console.error('Error registering user:', error.response?.data || error.message);
      setMessage(t('error_registering_user'));
    }
  };
  
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center p-6 pb-20"> {/* Added bottom padding */}
      <div className="bg-white p-12 rounded-lg shadow-lg w-full max-w-3xl"> {/* Increased max-width */}
        <h2 className="text-2xl font-bold mb-6 text-center text-primary-color">{t('sign_up')}</h2>
        {message && <p className="text-center text-red-600 mb-4">{message}</p>}
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-primary-color">{t('company_profile')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {t('company_profile_info')}
                </p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="col-span-full">
                  <div className="flex space-x-0">
                    <button
                      type="button"
                      className={`w-1/2 px-4 py-2 rounded-l-md ${entityType === 'Company' ? 'bg-primary-color text-white' : 'bg-gray-200 text-gray-900'}`}
                      onClick={() => handleEntityTypeChange('Company')}
                    >
                      {t('company')}
                    </button>
                    <button
                      type="button"
                      className={`w-1/2 px-4 py-2 rounded-r-md ${entityType === 'Portfolio' ? 'bg-primary-color text-white' : 'bg-gray-200 text-gray-900'}`}
                      onClick={() => handleEntityTypeChange('Portfolio')}
                    >
                      {t('portfolio')}
                    </button>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="company-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {entityType === 'Company' ? t('company_name') : t('portfolio_name')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="company-name"
                      id="company-name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('website')}
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary-color sm:max-w-md">
                      <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">http://</span>
                      <input
                        type="text"
                        name="website"
                        id="website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="www.example.com"
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="year-founded" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('year_founded')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      name="year-founded"
                      id="year-founded"
                      value={yearFounded}
                      onChange={(e) => setYearFounded(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                      min="1000"
                      max={new Date().getFullYear() + 2}
                    />
                  </div>
                </div>
                
                <div className="sm:col-span-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">{t('country')}</label>
            <div className="relative mt-2">
              <Listbox value={country} onChange={setCountry}>
                {({ open }) => (
                  <>
                    <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-color sm:text-sm sm:leading-6">
                      <span className="block truncate">{t(`Countries.${country.name}`)}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </span>
                    </ListboxButton>

                        <ListboxOptions
                      transition
                      className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                    >
                      {sortedCountryData.map((country) => (
                        <ListboxOption
                          key={country.code}
                          className={({ focus }) =>
                            classNames(
                              focus ? 'bg-primary-color text-white' : '',
                              !focus ? 'text-gray-900' : '',
                              'relative cursor-default select-none py-2 pl-3 pr-9',
                            )
                          }
                          value={country}
                        >
                          {({ selected, focus }) => (
                            <>
                              <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                {t(`Countries.${country.name}`)}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    focus ? 'text-white' : 'text-primary-color',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                  )}
                                >
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                      </>
                    )}
                  </Listbox>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
              <div>
                <h2 className="text-base font-semibold leading-7 text-primary-color">{t('personal_information')}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">{t('use_permanent_address')}</p>
              </div>

              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                <div className="sm:col-span-3">
                  <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('first_name')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      autoComplete="given-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('last_name')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      autoComplete="family-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('email_address')}
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('password')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="new-password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('confirm_password')}
                  </label>
                  <div className="mt-2">
                    <input
                      type="password"
                      name="confirm-password"
                      id="confirm-password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="new-password"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-color sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button 
              type="button" 
              className="text-sm text-gray-900"
              onClick={() => navigate('/')} // Navigate to home on cancel
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
            >
              {t('register')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
