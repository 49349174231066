import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';

export default function LiveModeToggle({ onToggle }) {
  const [enabled, setEnabled] = useState(() => {
    // Initialize state from localStorage
    const savedState = localStorage.getItem('liveModeEnabled');
    return savedState === 'true';
  });

  useEffect(() => {
    // Save state to localStorage whenever it changes
    localStorage.setItem('liveModeEnabled', enabled);
    onToggle(enabled);
  }, [enabled, onToggle]);

  const handleToggle = (newState) => {
    setEnabled(newState);
  };

  return (
    <div className="fixed top-3 right-4 z-50 flex items-center space-x-2">
      <Switch
        checked={enabled}
        onChange={handleToggle}
        className={`${
          enabled ? 'bg-primary-color' : 'bg-gray-200'
        } relative inline-flex h-5 w-9 flex-shrink-0 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-primary-color focus:ring-offset-2`}
      >
        <span
          className={`${
            enabled ? 'translate-x-5' : 'translate-x-1'
          } inline-block h-3 w-3 transform rounded-full bg-white transition-transform`}
        />
      </Switch>
      <span className="text-xs whitespace-nowrap">
        Live modus (save drafts automatically)
      </span>
    </div>
  );
}