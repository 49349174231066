import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'lord-icon-element';
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import RegistrationSuccess from './pages/auth/RegistrationSuccess';
import LoginSuccess from './pages/auth/LoginSuccess';
import CompanyDashboard from './pages/CompanyDashboard';
import PortfolioDashboard from './pages/PortfolioDashboard';
import Reports from './pages/Reports';
import Alerts from './pages/Alerts';
import Wizards from './pages/Wizards';
import KnowYourselfOverview from './pages/wizards/KnowYourselfWizard/KnowYourselfOverview';
import KnowYourselfWizardIndustry from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardIndustry';
import KnowYourselfWizardMarket from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardMarket';
import KnowYourselfWizardBusinessModel from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardBusinessModel';
import KnowYourselfWizardProduct from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardProduct';
import KnowYourselfWizardTeam from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardTeam';
import KnowYourselfWizardInvestment from './pages/wizards/KnowYourselfWizard/KnowYourselfWizardInvestment';
import StartupWizardOverview from './pages/wizards/StartupWizard/StartupWizardOverview';
import StartupWizardIntroduction from './pages/wizards/StartupWizard/StartupWizardIntroduction';
import StartupWizardLegalForm from './pages/wizards/StartupWizard/StartupWizardLegalForm';
import StartupWizardCompany from './pages/wizards/StartupWizard/StartupWizardCompany';
import StartupWizardFounders from './pages/wizards/StartupWizard/StartupWizardFounders';
import StartupWizardArticlesOfAssociation from './pages/wizards/StartupWizard/StartupWizardArticlesOfAssociation';
import StartupWizardShareholdersAgreement from './pages/wizards/StartupWizard/StartupWizardShareholdersAgreement';
import StartupWizardBankAccount from './pages/wizards/StartupWizard/StartupWizardBankAccount';
import StartupWizardEquityStory from './pages/wizards/StartupWizard/StartupWizardEquityStory';
import StartupWizardNotary from './pages/wizards/StartupWizard/StartupWizardNotary';
import ISMSOverview from './pages/wizards/ISMS/ISMSOverview';

import { WizardProvider } from './components/wizard_components/WizardContext';
import './components/wizard_components/Radiothumbs.css';
import Portfolio from './pages/Portfolio';
import Team from './pages/Team';
import Settings from './pages/Settings';
import Logout from './pages/Logout';
import LogoutSuccess from './pages/auth/LogoutSuccess';
import AuthProvider, { AuthContext } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import DashboardLayout from './components/DashboardLayout';
import ForgotPassword from './pages/auth/ForgotPassword';
import PasswordResetSuccess from './pages/auth/PasswordResetSuccess';
import ResetPassword from './pages/auth/ResetPassword';
import PasswordResetConfirmation from './pages/auth/PasswordResetConfirmation';
import DeletionSuccess from './pages/auth/DeletionSuccess';
import CompleteAccountSetup from './pages/auth/CompleteAccountSetup';
import SessionExpired from './pages/auth/SessionExpired';
import Home from './components/Home'; // Import the Home component
import './index.css'; // Import global styles including tailwind and other custom styles
import Footer from './components/Footer'; // Import Footer component

function App() {
  const { i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const checkI18nInitialized = () => {
      if (i18n.isInitialized && i18n.hasLoadedNamespace('translation')) {
        setIsInitialized(true);
      }
    };

    i18n.on('initialized', checkI18nInitialized);
    i18n.on('loaded', checkI18nInitialized);

    // Clean up listeners on unmount
    return () => {
      i18n.off('initialized', checkI18nInitialized);
      i18n.off('loaded', checkI18nInitialized);
    };
  }, [i18n]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  const token = sessionStorage.getItem('authToken');
  const expiration = sessionStorage.getItem('tokenExpiresAt');
  const now = Math.floor(new Date().getTime() / 1000);

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={token && now < expiration ? <Navigate to="/dashboard" /> : <Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/registration-success" element={<RegistrationSuccess />} />
          <Route path="/login-successful" element={<LoginSuccess />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password-reset-success" element={<PasswordResetSuccess />} />
          <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
          <Route path="/password-reset-confirmation" element={<PasswordResetConfirmation />} />
          <Route path="/deletion-success" element={<DeletionSuccess />} />
          <Route path="/complete-account-setup/:token" element={<CompleteAccountSetup />} />
          <Route path="/session-expired" element={<SessionExpired />} />
          <Route element={<DashboardLayout />}>
            <Route path="/dashboard" element={<PrivateRoute><DashboardWrapper /></PrivateRoute>} />
            <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
            <Route path="/alerts" element={<PrivateRoute><Alerts /></PrivateRoute>} />
            <Route path="/wizards" element={<PrivateRoute><Wizards /></PrivateRoute>} />
            <Route path="/know-yourself" element={<PrivateRoute><KnowYourselfOverview /></PrivateRoute>} />
            <Route path="/know-yourself/industry" element={<PrivateRoute><KnowYourselfWizardIndustry /></PrivateRoute>} />
            <Route path="/know-yourself/market" element={<PrivateRoute><KnowYourselfWizardMarket /></PrivateRoute>} />
            <Route path="/know-yourself/business-model" element={<PrivateRoute><KnowYourselfWizardBusinessModel /></PrivateRoute>} />
            <Route path="/know-yourself/product" element={<PrivateRoute><KnowYourselfWizardProduct /></PrivateRoute>} />
            <Route path="/know-yourself/team" element={<PrivateRoute><KnowYourselfWizardTeam /></PrivateRoute>} />
            <Route path="/know-yourself/investment" element={<PrivateRoute><KnowYourselfWizardInvestment /></PrivateRoute>} />

            {/* Startup Wizard routes */}
            <Route path="/startup-wizard/*" element={
              <WizardProvider wizardName="startup">
                <Routes>
                  <Route index element={<PrivateRoute><StartupWizardOverview /></PrivateRoute>} />
                  <Route path="introduction" element={<PrivateRoute><StartupWizardIntroduction /></PrivateRoute>} />
                  <Route path="legalForm" element={<PrivateRoute><StartupWizardLegalForm /></PrivateRoute>} />
                  <Route path="company" element={<PrivateRoute><StartupWizardCompany /></PrivateRoute>} />
                  <Route path="founders" element={<PrivateRoute><StartupWizardFounders /></PrivateRoute>} />
                  <Route path="articlesOfAssociation" element={<PrivateRoute><StartupWizardArticlesOfAssociation /></PrivateRoute>} />
                  <Route path="shareholdersAgreement" element={<PrivateRoute><StartupWizardShareholdersAgreement /></PrivateRoute>} />
                  <Route path="bankAccount" element={<PrivateRoute><StartupWizardBankAccount /></PrivateRoute>} />
                  <Route path="equityStory" element={<PrivateRoute><StartupWizardEquityStory /></PrivateRoute>} />
                  <Route path="notary" element={<PrivateRoute><StartupWizardNotary /></PrivateRoute>} />
                </Routes>
              </WizardProvider>
            } />

            {/* ISMS routes */}
            <Route path="/isms/*" element={
              <WizardProvider wizardName="isms">
                <Routes>
                  <Route index element={<PrivateRoute><ISMSOverview /></PrivateRoute>} />    
                </Routes>
              </WizardProvider>
            } />
            
            <Route path="/portfolio" element={<PrivateRoute><Portfolio /></PrivateRoute>} />
            <Route path="/team" element={<AdminRoute><Team /></AdminRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route path="/logout-success" element={<LogoutSuccess />} />
        </Routes>
        <Routes>
          {/* Footer routes */}
          <Route path="/" element={<Footer />} />
          <Route path="/login" element={<Footer />} />
          <Route path="/signup" element={<Footer />} />
          <Route path="/registration-success" element={<Footer />} />
          <Route path="/login-successful" element={<Footer />} />
          <Route path="/forgot-password" element={<Footer />} />
          <Route path="/password-reset-success" element={<Footer />} />
          <Route path="/reset-password/:resetToken" element={<Footer />} />
          <Route path="/password-reset-confirmation" element={<Footer />} />
          <Route path="/deletion-success" element={<Footer />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

function DashboardWrapper() {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <div>Loading...</div>;
  }

  return user.entity_type === 'Company' ? <CompanyDashboard /> : <PortfolioDashboard />;
}

export default App;
