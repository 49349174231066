import React from 'react';
import { Document, Packer, Paragraph, Table, TableRow, TableCell, BorderStyle, WidthType, AlignmentType, convertInchesToTwip, PageOrientation, Footer, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useWizard } from '../../wizard_components/WizardContext';

const ShareholdersListGenerator = () => {
  const { t } = useTranslation();
  const { wizardData } = useWizard();

  const generateShareholdersList = async () => {
    const { published } = wizardData;
    const company = published?.company || {};
    const founders = published?.founders?.founders || [];
    const legalForm = published?.legalForm || {};


    // Log the fetched user data for debugging
    console.log("Company Data: ", company);
    console.log("Founders Data: ", founders);
    console.log("Legal Form: ", legalForm);

    if (!company.companyName) {
      alert(t('Company data is not available. Please complete the company information first.'));
      return;
    }

    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toLocaleString('de-DE');

    const doc = new Document({
      sections: [{
        properties: {
          page: {
            margin: {
              top: convertInchesToTwip(1),
              right: convertInchesToTwip(1),
              bottom: convertInchesToTwip(1),
              left: convertInchesToTwip(1),
            },
            size: {
              orientation: PageOrientation.LANDSCAPE,
            },
          },
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "LISTE DER GESELLSCHAFTER",
                bold: true,
                font: "Arial",
                size: 22,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "der",
                font: "Arial",
                size: 22,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${company.companyName}`,
                bold: true,
                font: "Arial",
                size: 22,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `mit dem Sitz in ${company.place || ''}`,
                font: "Arial",
                size: 22,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `(Amtsgericht ${company.place || ''}, HRB neu)`,
                font: "Arial",
                size: 22,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `- Geschäftsanschrift: ${company.street || ''} ${company.streetAdditional || ''}, ${company.zip || ''} ${company.place || ''} -`,
                font: "Arial",
                size: 22,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "",
                font: "Arial",
                size: 22,
              }),
            ],
          }),
          new Table({
            width: {
              size: 0,
              type: WidthType.AUTO,
            },
            columnWidths: [cmToTwip(4), cmToTwip(4), cmToTwip(4), cmToTwip(4), cmToTwip(4), cmToTwip(4)],
            borders: {
              all: {
                style: BorderStyle.SINGLE,
                size: 1,
              },
            },
            rows: [
              new TableRow({
                children: [
                  createTableCell("Vor- und Nachname / Firma des Gesellschafters\nsowie\nWohnort / Sitz des Gesellschafters", 4, true),
                  createTableCell("Geburtsdatum / HR-Eintrag", 4, true),
                  createTableCell("lfd. Nrn. der Geschäftsanteile", 4, true),
                  createTableCell("Anzahl der Geschäftsanteile à nom. EUR 1,00", 4, true),
                  createTableCell("Prozentuale Beteiligung jedes Geschäftsanteils am Stammkapital", 4, true),
                  createTableCell("Gesamtumfang der Beteiligung des Gesellschafters am Stammkapital in Prozent", 4, true),
                ],
              }),
              ...(() => {
                let previousEndShare = 0;
                return founders.map((founder, index) => {
                  const startShare = previousEndShare + 1;
                  const endShare = startShare + Math.floor(founder.sharePercentage * company.shareCapital / 100) - 1;
                  previousEndShare = endShare;
                  return new TableRow({
                    children: [
                      createTableCell(`${founder.fullName}\nwohnhaft in ${founder.address.place}`, 4),
                      createTableCell(new Date(founder.dateOfBirth).toLocaleDateString('de-DE'), 4),
                      createTableCell(`${startShare} - ${endShare}`, 4),
                      createTableCell(Math.floor(founder.sharePercentage * company.shareCapital / 100).toString(), 4),
                      createTableCell(`${(100 / company.shareCapital).toFixed(3)}%`, 4),
                      createTableCell(`${founder.sharePercentage}%`, 4),
                    ],
                  });
                });
              })(),
              new TableRow({
                children: [
                  createTableCell("Stammkapital\n(= Euro)", 4),
                  createTableCell(company.shareCapital.toString(), 4),
                  createTableCell("", 4, false, 5), // Merge all cells but the first one
                ],
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "",
                font: "Arial",
                size: 22,
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `${company.place || ''}, den ${currentDateTime.toLocaleDateString('de-DE')}`,
                font: "Arial",
                size: 22,
              }),
            ],
            spacing: { before: 50, after: 50 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "\n_______________________________",
                font: "Arial",
                size: 22,
              }),
            ],
            spacing: { before: 50, after: 50 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: founders.find(f => f.isManagingDirector)?.fullName || "",
                font: "Arial",
                size: 22,
              }),
            ],
            spacing: { before: 50, after: 0 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: "(Geschäftsführer)",
                font: "Arial",
                size: 22,
              }),
            ],
            spacing: { before: 0, after: 50 },
          }),
        ],
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Exported from Cenedril at ${formattedDateTime}`,
                    font: "Arial",
                    size: 22,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
      }],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, "Liste_der_Gesellschafter.docx");
  };

  const createTableCell = (text, widthInCm, isHeader = false, colSpan = 1) => {
    return new TableCell({
      width: {
        size: cmToTwip(widthInCm),
        type: WidthType.DXA,
      },
      margins: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      shading: isHeader ? { fill: "D3D3D3" } : undefined,
      columnSpan: colSpan,
      children: [new Paragraph({
        children: [
          new TextRun({
            text,
            font: "Arial",
            size: 20, // 10pt in half-points
          }),
        ],
        alignment: isHeader ? AlignmentType.CENTER : AlignmentType.LEFT,
      })],
    });
  };

  const cmToTwip = (cm) => {
    return cm * 567; // 1 cm = 567 twips
  };

  return (
    <button
      onClick={generateShareholdersList}
      className="bg-primary-color text-white px-4 py-2 rounded hover:bg-secondary-color flex items-center"
      >
      <img src={process.env.PUBLIC_URL + '/ux_icons/download.svg'} alt="icon" className="h-5 w-5 mr-2 fill-current text-white" />
      {t('Shareholders List')}
    </button>
  );
};

export default ShareholdersListGenerator;