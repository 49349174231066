// src/components/Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-6">
      <div className="flex flex-col items-center mb-6">
        <img src="/logo_twocolor.svg" alt="Cenedril Icon" className="h-32 mb-4" />
      </div>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        <h1 className="text-2xl font-bold mb-6 text-primary-color">{t('welcome_to_cenedril')}</h1>
        <Link 
          to="/login" 
          className="inline-block w-full rounded-md bg-primary-color px-6 py-3 mb-4 text-white font-semibold shadow-sm hover:bg-secondary-color hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-color"
        >
          {t('login')}
        </Link>
        <p className="text-primary-color text-sm font-semibold">
          <Link to="/signup" className="text-primary-color hover:text-secondary-color">
            {t('sign_up')}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Home;
