import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StartupWizardShareholdersAgreement = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{t('StartupWizard.shareholdersAgreement')}</h1>
      <p className="mb-4">{t('StartupWizard.shareholdersAgreementDescription')}</p>
      <Link to="/startup-wizard/bank-account" className="bg-primary-color text-white px-4 py-2 rounded">
        {t('StartupWizard.next')}
      </Link>
    </div>
  );
};

export default StartupWizardShareholdersAgreement;