// src/pages/CompanyDashboard.js
import React, { useState, useEffect } from 'react';
import axios from '../axios';

const CompanyDashboard = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [companyKey, setCompanyKey] = useState('');
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const fetchCompanyData = async () => {
    try {
      const response = await axios.get('/api/v1/live_data/company_portfolios', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      setPortfolios(response.data.portfolios);
      setCompanyKey(response.data.company_key);

      const userDataResponse = await axios.get('/api/v1/live_data/user_data', {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('authToken')}`
        }
      });
      setCompanyData(userDataResponse.data.entity);
    } catch (error) {
      console.error('Error fetching company data:', error);
    }
  };

  const renderCompanyDetails = () => {
    console.log('Company Data:', companyData);

    if (
      companyData &&
      companyData.name &&
      companyData.know_yourself_response.industry &&
      companyData.know_yourself_response.offices &&
      Array.isArray(companyData.know_yourself_response.offices) &&
      companyData.know_yourself_response.remoteWorkers !== undefined
    ) {
      const locations = companyData.know_yourself_response.offices.map(office => office.location).join(', ');
      const totalEmployees = companyData.know_yourself_response.remoteWorkers + companyData.know_yourself_response.offices.reduce((total, office) => total + office.employees, 0);
      const sectionPart = companyData.know_yourself_response.section
        ? `, focusing on ${companyData.know_yourself_response.section}`
        : '';

      return (
        <p>{companyData.name} is a {companyData.know_yourself_response.industry} company, working primarily from {locations}{sectionPart} with the strength of a {totalEmployees} person team.</p>
      );
    }
    return null;
  };

  return (
    <div>
      <h1>Company Dashboard</h1>
      <p>Your Public Company Key (share the infos from Know-Yourself-Wizard): {companyKey}</p>
      <p>Add option for secret, one-time keys (get all infos) that can be revoked</p>
      <h2>Connected Portfolios</h2>
      <ul>
        {portfolios.map((portfolio) => (
          <li key={portfolio.name}>
            {portfolio.name} - {portfolio.website} - {portfolio.country}
          </li>
        ))}
      </ul>
      {renderCompanyDetails()}
    </div>
  );
};

export default CompanyDashboard;
