// src/data/glossaryTerms.js

export const glossaryTerms = {
    'en': {
      'Pitch deck': 'A presentation used to showcase a business idea to potential investors.',
      'Ordinary resolutions': 'Decisions made by the shareholders of a company that require a simple or smaller majority vote, because they are routine.',
      'Extraordinary resolutions': 'Decisions made by the shareholders of a company that require a higher majority vote than ordinary resolutions, because they are more significant.',
    },
    'de': {
      'Pitch Deck': 'Eine Präsentation, die verwendet wird, um eine Geschäftsidee potenziellen Investoren vorzustellen.',
    },
  };