import React, { useState, useContext, useEffect } from 'react';
import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
  ShieldExclamationIcon,
  ArrowLeftOnRectangleIcon,
  Square3Stack3DIcon,
} from '@heroicons/react/24/outline';
import { NavLink, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Sidebar() {
  const { user, logout } = useContext(AuthContext);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.language) {
      i18n.changeLanguage(user.language);
    }
  }, [user, i18n]);

  const navigation = [
    { name: 'sidebar.dashboard', to: '/dashboard', icon: HomeIcon },
    { name: 'sidebar.reports', to: '/reports', icon: ChartPieIcon },
    { name: 'sidebar.alerts', to: '/alerts', icon: ShieldExclamationIcon },
  ];

  if (user?.entity_type === 'Company') {
    navigation.push({ name: 'sidebar.wizards', to: '/wizards', icon: Square3Stack3DIcon });
  }

  if (user?.entity_type === 'Portfolio') {
    navigation.push({ name: 'sidebar.portfolio', to: '/portfolio', icon: DocumentDuplicateIcon });
  }

  if (user?.role === 'admin') {
    navigation.push({ name: 'sidebar.team', to: '/team', icon: UsersIcon });
  }

  navigation.push({ name: 'sidebar.settings', to: '/settings', icon: AdjustmentsHorizontalIcon });
  navigation.push({ name: 'sidebar.logout', to: '#', icon: ArrowLeftOnRectangleIcon, action: () => setOpen(true), noActive: true });

  const handleLogout = () => {
    logout();
    navigate('/logout-success');
  };

  return (
    <>
      <div className="sidebar hidden md:flex flex-col gap-y-5 bg-primary-color px-6 w-64">
        <div className="flex h-24 items-center justify-center">
          <img className="h-16 w-auto mt-10 mb-5" src="/LogoOneColor_white.svg" alt="Cenedril Logo" />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <NavLink
                      to={item.to}
                      className={({ isActive }) =>
                        classNames(
                          item.noActive ? 'text-white hover:bg-secondary-color hover:bg-opacity-50' :
                          isActive ? 'bg-secondary-color text-white' : 'text-white hover:bg-secondary-color hover:bg-opacity-50',
                          'sidebar-link group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6'
                        )
                      }
                      onClick={item.action}
                    >
                      <item.icon
                        className={classNames(
                          'h-6 w-6 shrink-0',
                          { 'text-white': item.current, 'text-white group-hover:text-white': !item.current }
                        )}
                        aria-hidden="true"
                      />
                      {t(item.name)}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>
            <li className="-mx-6 mt-auto">
              <a
                href="#"
                className="sidebar-link flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-white hover:bg-secondary-color hover:text-white"
              >
                <img
                  className="h-8 w-8 rounded-full bg-secondary-color"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
                <span className="sr-only">Your profile</span>
                <span aria-hidden="true">{user?.first_name} {user?.last_name}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <Transition.Root show={open} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        {t('logoutConfirmation')}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {t('logoutConfirmationText')}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={handleLogout}
                    >
                      {t('logoutConfirm')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      {t('cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
