import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const ThreatItem = ({ threat }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [vulnerabilities, setVulnerabilities] = useState(null);
  const [loadingVulnerabilities, setLoadingVulnerabilities] = useState(false);
  const [vulnerabilitiesError, setVulnerabilitiesError] = useState(null);

  const toggleOpen = () => {
    if (!isOpen && !vulnerabilities && !loadingVulnerabilities) {
      fetchVulnerabilities();
    }
    setIsOpen(!isOpen);
  };

  const fetchVulnerabilities = async () => {
    try {
      setLoadingVulnerabilities(true);
      const response = await axios.get(`/api/v1/static_data/vulnerabilities_for_threat/${threat.id}`);
      setVulnerabilities(response.data);
    } catch (error) {
      console.error('Error fetching vulnerabilities:', error);
      setVulnerabilitiesError('Failed to load vulnerabilities');
    } finally {
      setLoadingVulnerabilities(false);
    }
  };

  return (
    <div className="border border-gray-200 rounded-lg mb-2">
      <button
        type="button"
        className="flex items-center justify-between w-full p-2 text-left"
        onClick={toggleOpen}
      >
        <span className="flex items-center">
          {threat.icon && (
            <img src={`/is_icons/${threat.icon}`} alt="" className="w-6 h-6 mr-2" />
          )}
          {threat.threat}
        </span>
        <svg
          className={`w-4 h-4 ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="pl-4">
          {vulnerabilitiesError ? (
            <p className="text-red-500">{vulnerabilitiesError}</p>
          ) : loadingVulnerabilities ? (
            <p>Loading vulnerabilities...</p>
          ) : vulnerabilities && vulnerabilities.length > 0 ? (
            <ul className="list-disc pl-5">
              {vulnerabilities.map(vuln => (
                <li key={vuln.id}>{vuln.vulnerability}</li>
              ))}
            </ul>
          ) : (
            <p>No vulnerabilities found.</p>
          )}
        </div>
      )}
    </div>
  );
};

const RiskControlItem = ({ riskControl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const fetchDetails = async () => {
    try {
      const [descriptionRes, executionCriteriaRes, threatsRes] = await Promise.all([
        axios.get(`/api/v1/static_data/risk_control_description_for_risk_control/${riskControl.id}`),
        axios.get(`/api/v1/static_data/risk_control_execution_criteria_for_risk_control/${riskControl.id}`),
        axios.get(`/api/v1/static_data/threats_for_risk_control/${riskControl.id}`)
      ]);

      setDetails({
        description: Array.isArray(descriptionRes.data) ? descriptionRes.data : [descriptionRes.data],
        executionCriteria: Array.isArray(executionCriteriaRes.data) ? executionCriteriaRes.data : [executionCriteriaRes.data],
        threats: threatsRes.data
      });
    } catch (error) {
      console.error('Error fetching risk control details:', error);
      setError('Failed to load details');
    }
  };

  const toggleOpen = () => {
    if (!isOpen && !details && !error) {
      fetchDetails();
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-gray-200 rounded-lg mb-4 bg-white">
      <button
        type="button"
        className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border-b border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 hover:bg-gray-100"
        onClick={toggleOpen}
      >
        <span className="flex items-center">
          {riskControl.icons && (
            <img src={`/is_icons/${riskControl.icons}`} alt="" className="w-6 h-6 mr-2" />
          )}
          {riskControl.control_name}
        </span>
        <svg
          className={`w-6 h-6 ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>
      {isOpen && (
        <div className="p-5 border-t border-gray-200">
          {error ? (
            <p className="text-red-500">{error}</p>
          ) : details ? (
            <>
              <h3 className="font-bold mb-2">{t('Description')}</h3>
              {details.description
                .sort((a, b) => a.text_block_no - b.text_block_no)
                .map((desc, index) => (
                  <div key={index} className="mb-4">
                    <p>{desc.bold === 'TRUE' ? <strong>{desc.text}</strong> : desc.text}</p>
                    {desc.execution_criteria && (
                      <ul className="ml-6 list-disc">
                        {details.executionCriteria
                          .filter(criteria =>
                            criteria.execution_criteria.startsWith(desc.execution_criteria)
                          )
                          .map((criteria, idx) => (
                            <li key={idx}>
                              {criteria.text_form}
                            </li>
                          ))}
                      </ul>
                    )}
                  </div>
              ))}
              <h3 className="font-bold mb-2">{t('Associated Threats')}</h3>
              <div className="space-y-2">
                {details.threats.map(threat => (
                  <ThreatItem key={threat.id} threat={threat} />
                ))}
              </div>
            </>
          ) : (
            <p>Loading details...</p>
          )}
        </div>
      )}
    </div>
  );
};


const ISMSOverview = () => {
  const [riskControls, setRiskControls] = useState([]);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchRiskControls = async () => {
      try {
        const response = await axios.get('/api/v1/static_data/risk_controls');
        setRiskControls(response.data);
      } catch (error) {
        console.error('Error fetching risk controls:', error);
        setError('Failed to load risk controls');
      }
    };

    fetchRiskControls();
  }, []);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (riskControls.length === 0) {
    return <div>Loading risk controls...</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">{t('ISMS Risk Controls Overview')}</h1>
      {riskControls.map(riskControl => (
        <RiskControlItem key={riskControl.id} riskControl={riskControl} />
      ))}
    </div>
  );
};

export default ISMSOverview;
