import React, { useState } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { IoTrashOutline } from 'react-icons/io5';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { countryData } from '../../constants/countryData';

const sortedCountryData = [...countryData].sort((a, b) => a.name.localeCompare(b.name));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const FounderCard = ({
  founder = {
    fullName: '',
    dateOfBirth: '',
    nationality: '',
    address: { street: '', streetAdditional: '', zip: '', place: '', country: '' },
    email: '',
    phone: '',
    isManagingDirector: false,
    sharePercentage: 0,
    capitalInvested: 0,
  },
  index,
  onRemove,
  setFieldValue,
  values,
  totalShareCapital,
}) => {
  const { t } = useTranslation();
  const [isEditingCapital, setIsEditingCapital] = useState(false);

  const formatNumber = (number, decimalPlaces = 2) => {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    }).format(number);
  };

  const handleCapitalInvestedChange = (event) => {
    let value = event.target.value.replace(/[^\d.]/g, '');
    let newCapitalInvested = parseFloat(value);
    if (isNaN(newCapitalInvested) || newCapitalInvested < 0) {
      newCapitalInvested = 0;
    }
    if (newCapitalInvested > totalShareCapital) {
      newCapitalInvested = totalShareCapital;
    }
    const newSharePercentage = (newCapitalInvested / totalShareCapital) * 100;
    setFieldValue(`founders[${index}].capitalInvested`, newCapitalInvested);
    setFieldValue(`founders[${index}].sharePercentage`, newSharePercentage);
  };

  const handleSharePercentageChange = (event) => {
    const newSharePercentage = parseFloat(event.target.value) || 0;
    const newCapitalInvested = (newSharePercentage / 100) * totalShareCapital;
    setFieldValue(`founders[${index}].sharePercentage`, newSharePercentage);
    setFieldValue(`founders[${index}].capitalInvested`, newCapitalInvested);
  };

  const handleFieldChange = (fieldName, value) => {
    setFieldValue(`founders[${index}].${fieldName}`, value);
  };

  const handleAddressChange = (fieldName, value) => {
    setFieldValue(`founders[${index}].address.${fieldName}`, value);
  };

  return (
    <div className="border rounded-lg p-4 mb-4 bg-white">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold">{founder?.fullName || `${t('Founder')} ${index + 1}`}</h3>
        <button
          type="button"
          className="text-red-500 hover:text-red-700 transition-colors duration-200"
          onClick={onRemove}
          aria-label={t('Remove')}
        >
          <IoTrashOutline className="w-5 h-5" />
        </button>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-4">
        {/* Full Name */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Full Name')}</label>
          <Field
            name={`founders[${index}].fullName`}
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleFieldChange('fullName', e.target.value)}
          />
        </div>
        {/* Date of Birth */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Date of Birth')}</label>
          <Field
            name={`founders[${index}].dateOfBirth`}
            type="date"
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleFieldChange('dateOfBirth', e.target.value)}
          />
        </div>
        {/* Nationality */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Nationality')}</label>
          <Listbox
            value={sortedCountryData.find((c) => c.code === founder?.nationality) || sortedCountryData[0]}
            onChange={(selectedCountry) => {
              handleFieldChange('nationality', selectedCountry.code);
            }}
          >
            {({ open }) => (
              <>
                <div className="relative mt-1">
                  <ListboxButton className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-color focus:outline-none focus:ring-1 focus:ring-primary-color sm:text-sm">
                    <span className="block truncate">
                      {t(`Countries.${sortedCountryData.find(c => c.code === founder?.nationality)?.name || ''}`)}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <ListboxOptions
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {sortedCountryData.map((country) => (
                      <ListboxOption
                        key={country.code}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-primary-color' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={country}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {t(`Countries.${country.name}`)}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary-color',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </>
            )}
          </Listbox>
        </div>
        {/* Email */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Email')}</label>
          <Field
            name={`founders[${index}].email`}
            type="email"
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleFieldChange('email', e.target.value)}
          />
        </div>
        {/* Phone */}
        <div>
          <label className="block text-sm font-medium text-gray-700">{t('Phone')}</label>
          <Field
            name={`founders[${index}].phone`}
            type="tel"
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleFieldChange('phone', e.target.value)}
          />
        </div>
      </div>
      {/* Address */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Address')}</label>
        <div className="grid grid-cols-2 gap-4">
          {/* Street */}
          <Field
            name={`founders[${index}].address.street`}
            placeholder={t('Street')}
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleAddressChange('street', e.target.value)}
          />
          {/* Additional */}
          <Field
            name={`founders[${index}].address.streetAdditional`}
            placeholder={t('Additional')}
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleAddressChange('streetAdditional', e.target.value)}
          />
          {/* ZIP */}
          <Field
            name={`founders[${index}].address.zip`}
            placeholder={t('ZIP')}
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleAddressChange('zip', e.target.value)}
          />
          {/* Place */}
          <Field
            name={`founders[${index}].address.place`}
            placeholder={t('Place')}
            className="mt-1 block w-full border-gray-300 rounded-md"
            onChange={(e) => handleAddressChange('place', e.target.value)}
          />
          {/* Country */}
          <Listbox
            value={sortedCountryData.find((c) => c.code === founder?.address?.country) || sortedCountryData[0]}
            onChange={(selectedCountry) => {
              handleAddressChange('country', selectedCountry.code);
            }}
          >
            {({ open }) => (
              <>
                <div className="relative mt-1">
                  <ListboxButton className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary-color focus:outline-none focus:ring-1 focus:ring-primary-color sm:text-sm">
                    <span className="block truncate">
                      {t(`Countries.${sortedCountryData.find(c => c.code === founder?.address?.country)?.name || ''}`)}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <ListboxOptions
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {sortedCountryData.map((country) => (
                      <ListboxOption
                        key={country.code}
                        className={({ active }) =>
                          classNames(
                            active ? 'text-white bg-primary-color' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={country}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {t(`Countries.${country.name}`)}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-primary-color',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      {/* Managing Director Checkbox */}
      <div className="mt-4">
        <label className="flex items-center">
          <Field
            type="checkbox"
            name={`founders[${index}].isManagingDirector`}
            className="mr-2"
            onChange={(e) => handleFieldChange('isManagingDirector', e.target.checked)}
          />
          <span className="text-sm font-medium text-gray-700">{t('Will be managing director?')}</span>
        </label>
      </div>
      {/* Share Percentage */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Share Percentage')}</label>
        <div className="flex items-center">
          <input
            type="range"
            min="0"
            max="100"
            step="0.1"
            value={founder?.sharePercentage || 0}
            onChange={handleSharePercentageChange}
            className="w-full mr-4"
          />
          <span>{formatNumber(founder?.sharePercentage || 0)}%</span>
        </div>
      </div>
      {/* Capital Invested */}
      <div className="mt-4">
        <label className="block text-sm font-medium text-gray-700">{t('Capital Invested')}</label>
        {isEditingCapital ? (
          <input
            type="number"
            value={founder?.capitalInvested || 0}
            onChange={handleCapitalInvestedChange}
            onBlur={() => setIsEditingCapital(false)}
            className="mt-1 block w-full border-gray-300 rounded-md"
            autoFocus
          />
        ) : (
          <div
            onClick={() => setIsEditingCapital(true)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 cursor-text"
          >
            {formatNumber(founder?.capitalInvested || 0)} €
          </div>
        )}
      </div>
    </div>
  );
};

export default FounderCard;
