import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import MarketMap from './MarketMap';
import { countryData } from '../constants/countryData';
import DocumentGenerator from './DocumentGenerator';

const KnowYourselfOverviewDisplay = ({ data, generalInfo, pages }) => {
  const { t } = useTranslation();

  const formatCurrency = (amount, currency) => {
    if (!amount) return t('Not specified');
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency || 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  const renderDemography = (ageGroup, genders, income) => {
    const ageRange = ageGroup ? `${ageGroup[0]} - ${ageGroup[1]} years of age` : t('Not specified');
    const genderInfo = genders || t('Not specified');
    const incomeInfo = income && income.length > 0 ? income.join(', ') : t('Not specified');
    return `${ageRange}, ${genderInfo}, ${incomeInfo}`;
  };

  const renderProducts = (products) => {
    if (!products || products.length === 0) return t('Not specified');
    return (
      <>
        {products.map((product, index) => (
        <div>
        <p key={index} className="mb-2 mt-0 text-sm">
            <b>{product.name}</b>
            </p>
          <p className="mb-2 mt-0 text-sm">
          {product.description} {t(`KnowYourselfWizard.${product.devStage}`)} {t('since')} {t(product.month)} {product.year}. &nbsp;
            {product.usp && product.usp.length > 0 && (
              <>
                {t('KnowYourselfWizard.Its USPs are')} {product.usp.map(usp => t(`KnowYourselfWizard.${usp}`)).join(', ')}.
              </>
            )}
          </p>
          </div>
        ))}
      </>
    );
  };

  const getResponsibilitiesForPerson = (person) => {
    return Object.entries(data.responsibilities || {})
      .filter(([_, responsible]) => responsible === `${person.firstName} ${person.lastName}`)
      .map(([resp]) => t(`${resp}`));
  };

  const renderTeamMembers = () => {
    const allMembers = [
      ...(data.founders || []).map(f => ({ ...f, isFounder: true })),
      ...(data.keyTeamMembers || [])
    ];
  
    if (allMembers.length === 0) return t('Not specified');
  
    const totalEmployees = (data.offices || []).reduce((sum, office) => sum + (office.employees || 0), 0);
    const officeCount = (data.offices || []).length - 1; // -1 because remote workers is not an office

    return (
      <>
        <ul className="list-disc pl-5">
          {allMembers.map((member, index) => {
            const responsibilities = getResponsibilitiesForPerson(member);
            return (
              <li key={index}>
                <strong>{member.firstName} {member.lastName}</strong> - {member.role}
                {member.isFounder ? ` (${t('Founder')})` : member.yearInCompany ? ` (${t('since')} ${member.yearInCompany})` : ''}
                {responsibilities.length > 0 && `, ${t('responsible for')} ${responsibilities.join(', ')}`}
              </li>
            );
          })}
        </ul>
        <p className="mt-2 text-sm">
          {t('The full team consists of {{totalEmployees}} employees and is spread across {{officeCount}} offices.', { totalEmployees, officeCount })}
        </p>
      </>
    );
  };

  const renderRevenueCategories = (categories) => {
    if (!categories || Object.keys(categories).length === 0) return t('Not specified');
    return (
      <ul className="list-disc pl-5">
        {Object.entries(categories).map(([category, models]) => (
          <li key={category}>
            <strong>{t(`RevenueCategories.${category}`)}</strong>: {models.join(', ')}
          </li>
        ))}
      </ul>
    );
  };

  const renderFundingInfo = () => {
    const { 
      investmentStage, 
      lookingForFunding, 
      fundingAmount, 
      fundingCurrency, 
      targetFundingRound, 
      exitStrategies,
      investmentNotes 
    } = data;
  
    let fundingText = t('fundingInfoRecentRound', {
      companyName: generalInfo['Company Name'],
      investmentStage: t(`InvestmentStages.${investmentStage}`)
    });
  
    if (lookingForFunding === 'Yes') {
      fundingText += t('fundingInfoLookingForFunding', {
        amount: formatCurrency(fundingAmount, fundingCurrency),
        round: t(`InvestmentStages.${targetFundingRound}`)
      });
    } else {
      fundingText += t('fundingInfoNotLookingForFunding');
    }
  
    if (exitStrategies && exitStrategies.length > 0) {
      fundingText += t('fundingInfoExitStrategies', {
        strategies: exitStrategies.map(strategy => t(`ExitStrategies.${strategy}`)).join(', ')
      });
    }
  
    if (investmentNotes) {
      fundingText += t('fundingInfoNotes', { notes: investmentNotes });
    }
  
    return fundingText;
  };

  const renderConsolidatedInfo = (generalInfo, data) => {
    const { 'Company Name': name, Website: website, Country: countryCode, 'Year Founded': yearFounded } = generalInfo;
    const { industry, sector, otherIndustry, otherSector, regions, offices = [] } = data;
  
    const country = countryData.find(c => c.code === countryCode);
    const countryName = country ? country.name : countryCode;  // Fallback to code if not found
    const totalEmployees = offices.reduce((sum, office) => sum + (office.employees || 0), 0);
    const officeCount = offices.length - 1;

    const regionText = regions.length > 1 
      ? t('consolidatedInfoMultipleRegions', { regions: regions.map(r => t(`Regions.${r}`)).join(t('andSeparator')) })
      : t('consolidatedInfoSingleRegion', { region: t(`Regions.${regions[0]}`) });

    let text = '';

    // Start of the text with industry information
    if (industry === 'Other') {
      text += t('consolidatedInfoPartOther', { name, website, yearFounded, otherIndustry });
    } else {
      text += t('consolidatedInfoPart1', { name, website, industry: t(`Industries.${industry}`), yearFounded });
    }

    // Country and regions
    text += ' ' + t('consolidatedInfoLocation', { 
      country: t(`Countries.${countryName}`),  // Translate the country name
      regionText, 
      marketPlural: regions.length > 1 ? t('markets') : t('market')
    });

    // Sector and team size
    if (industry === 'Other') {
      text += ' ' + t('consolidatedInfoTeamSizeNoSector', { totalEmployees, officeCount });
    } else if (sector === 'Other') {
      text += ' ' + t('consolidatedInfoTeamSizeOtherSector', { totalEmployees, officeCount, otherSector });
    } else {
      text += ' ' + t('consolidatedInfoTeamSizeWithSector', { sector: t(`Sectors.${sector}`), totalEmployees, officeCount });
    }

    return (
      <p className="text-sm mt-0">
        <Trans components={[<strong />, <strong />]}>
          {text}
        </Trans>
      </p>
    );
  };

  const renderMarketSection = (data) => {
    return (
      <div>
        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt className="text-sm font-medium text-gray-900">{t('Market')}</dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"></dd>
        </div>
        <div className="px-4 sm:px-6">
          <div className="sm:col-span-3">
            <MarketMap subregions={data.subregions} />
            {data.marketNotes && (
              <p className="mt-4 text-sm text-gray-500 italic">
                {data.marketNotes}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderBusinessModelSection = (data) => {
    const { clientele, businessSectors, revenueCategories, modelNotes, clienteleAgeGroup, clienteleGenders, clienteleIncome } = data;
    const { 'Company Name': name } = generalInfo;
  
    const translateClientele = (clienteleType) => t(`KnowYourselfWizard.${clienteleType}`);
    const translateSector = (sector) => t(`Industries.${sector}`);
    const translateRevenueModel = (model) => t(`KnowYourselfWizard.${model}`);
    const translateIncome = (income) => t(`KnowYourselfWizard.${income}`);
  
    const formatIncome = (incomes) => {
      if (incomes.includes('All incomes')) return t('KnowYourselfWizard.All incomes');
      if (incomes.length === 1) return translateIncome(incomes[0]);
      const sortOrder = ['Low-income', 'Middle-income', 'High-income', 'Super-high-income'];
      const sortedIncomes = incomes.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
      return `${translateIncome(sortedIncomes[0])} ${t('KnowYourselfWizard.to')} ${translateIncome(sortedIncomes[sortedIncomes.length - 1])}`;
    };
  
    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">{t('businessModel')}</dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <p className="mb-3 mt-0 text-sm">
            {name} {t('KnowYourselfWizard.serves')} <b>{clientele.map(translateClientele).join(t('andSeparator'))}</b> {t('KnowYourselfWizard.customers')}. 
            {businessSectors.length > 0 && ` ${t('KnowYourselfWizard.businessSectors')} ${t('KnowYourselfWizard.include')} ${businessSectors.map(translateSector).join(', ')}.`}
          </p>
          
          <p className="mb-3 text-sm">
            {t('KnowYourselfWizard.revenueModels')} {t('KnowYourselfWizard.consist of')}: 
            {Object.entries(revenueCategories).map(([category, models], index, array) => {
              if (models.length > 0) {
                return `${t(`KnowYourselfWizard.${category}`)} (${models.map(translateRevenueModel).join(', ')})${index === array.length - 1 ? '.' : ', '}`
              }
              return null;
            }).filter(Boolean).join(' ')}
          </p>
          
          <p className="mb-3 text-sm">
            {t('KnowYourselfWizard.The target group is')} {clienteleAgeGroup[0]} - {clienteleAgeGroup[1]} {t('KnowYourselfWizard.years old')},&nbsp; 
            {clienteleGenders === 'All' ? t('KnowYourselfWizard.All') : t(`KnowYourselfWizard.${clienteleGenders}`)},&nbsp;
            {formatIncome(clienteleIncome)}.
          </p>
          
          {modelNotes && (
            <p className="italic text-gray-500 mt-4 text-sm">
              "{modelNotes}"
            </p>
          )}
        </dd>
      </div>
    );
  };

  const renderSection = (title, content) => {
    if (!content || Object.keys(content).length === 0) return null;
  
    if (title === 'Industry' || title === 'Market' || title === t('KnowYourselfProgress.businessModel')) return null;
  
    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-900">{t(title)}</dt>
        <dd className="text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          {title === 'General Information' ? (
            renderConsolidatedInfo(generalInfo, data)
          ) : (
            <>
              {title === t('KnowYourselfProgress.team') && renderTeamMembers()}
              {title === t('KnowYourselfProgress.funding') && (
                <p className="whitespace-pre-line text-sm mt-0">{renderFundingInfo()}</p>
              )}
              {title === t('KnowYourselfProgress.productsServices') && renderProducts(content.products)}
              {title !== t('KnowYourselfProgress.team') && 
               title !== t('KnowYourselfProgress.funding') && 
               title !== t('KnowYourselfProgress.productsServices') && (
                <ul className="list-disc pl-5">
                  {Object.entries(content).map(([key, value]) => {
                    if (!value || value === t('Not specified')) return null;
                    if (['founders', 'keyTeamMembers', 'responsibilities', 'offices', 'investmentStage', 'lookingForFunding', 'fundingAmount', 'fundingCurrency', 'targetFundingRound', 'exitStrategies', 'investmentNotes', 'products'].includes(key)) return null;
                    
                    let renderedValue;
                    switch (key) {
                      case 'revenueCategories':
                        renderedValue = renderRevenueCategories(value);
                        break;
                      case 'clienteleAgeGroup':
                      case 'clienteleGenders':
                      case 'clienteleIncome':
                        // These will be handled together in a separate entry
                        return null;
                      default:
                        renderedValue = Array.isArray(value) ? value.join(', ') : value;
                    }
                    return (
                      <li key={key}>
                        <strong>{t(`KnowYourselfWizard.${key}`) || t(key) || t(`ExitStrategies.${key}`) || key}</strong>: {renderedValue}
                      </li>
                    );
                  })}
                  {content.clienteleAgeGroup && (
                    <li>
                      <strong>{t('Demography of Users')}</strong>: {renderDemography(content.clienteleAgeGroup, content.clienteleGenders, content.clienteleIncome)}
                    </li>
                  )}
                </ul>
              )}
            </>
          )}
        </dd>
      </div>
    );
  };

  return (
    <div id="know-yourself-overview" className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="mt-2 text-lg font-medium leading-6 text-gray-900">{t('Know Yourself Summary')}</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">{t('Latest published data')}</p>
        </div>
        <DocumentGenerator data={data} generalInfo={generalInfo} pages={pages} />
      </div>
      <div className="border-t border-gray-200">
      <dl className="divide-y divide-gray-200">
        {renderSection('General Information', { ...generalInfo, ...data })}
      </dl>
      <hr className="my-4"/>
      {renderMarketSection(data)}
      <hr className="my-4"/>
      {renderBusinessModelSection(data)}
      <hr className="my-4"/>
      <dl className="divide-y divide-gray-200">
        {pages
          .filter(page => !['Industry', 'Market', 'Branche', 'Markt'].includes(page.name))
          .map(page => {
            const pageData = page.fields.reduce((acc, field) => {
              if (data[field]) {
                acc[field] = data[field];
              }
              return acc;
            }, {});
            return renderSection(page.name, pageData);
          })
        }
      </dl>
      </div>
    </div>
  );
};

export default KnowYourselfOverviewDisplay;